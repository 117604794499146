<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn v-if="false" @click="constructionGoodsImport()"> чеклист импорт </v-btn>
      <v-btn v-if="false" @click="constructionGoodsAnalize()"> чеклист анализ </v-btn>
      <v-btn v-if="false" @click="goodsFinanceImport()">Закут товаров</v-btn>
      <v-btn v-if="!false" @click="cashOutAnalize()">Расход анализ </v-btn>
      <v-btn v-if="!false" @click="cashInAnalize()">Приход анализ </v-btn>
      <v-btn v-if="false" @click="investementImport()">import Investor </v-btn>
      <v-btn v-if="false" @click="serviceFinanceImport()">import Services </v-btn>
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit"></edit-dialog>
    </portal>
    <a-table-f-data2 ref="table" :model="model" :dataTable="data" :useQuery="false"> </a-table-f-data2>
  </static-fullscreen-card>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  mixins: [getAccess],
  components: {
    editDialog: () => import("./dialogs/financeDialog2"),
  },
  data() {
    return {
      idEdit: 0,
      data: [],
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.finance,
      model: [],
      url: "/mechti/finance",
      defaults: {
        sort: { key: "id", order: "DESC" },
        paramName: "finance",
      },
    };
  },
  created() {
    this.$root.title = "Импорт";
  },
  computed: {},
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    async constructionGoodsImport(id) {
      return;
      // id = 41;
      const resp = await this.$axios.get("/accounting/doc/order_good", {
        params: {
          filters: {
            status: 2,
            code_doc: -1,
            object_code: id,
            operation_type: 1,
            type: 1,
          },
        },
      });
      let orders = resp.data.data;
      let api = "/accounting/doc/order_withdrawal";
      this.data.forEach(async (el) => {
        let order = orders.find((o) => o.object_code == el.object_id);
        if (order?.id) {
          let data = {
            date_doc: el.date_doc,
            vendor_id: order.vendor_id,
            object_id: order.object_code,
            value: el.price_real,
            files: el.files,
            cashbox_id: 1,
            code_doc: -1,
            status: 1,
            operation_type: 3,
            parent_id: order.id,
            parent_name: "AccountingDocOrderGoodModel",
            data: {
              type: 1,
              object_code: order.object_code,
              note: `Импорт финансы id:${el.finance_id}`,
              finance_id: el.finance_id,
            },
          };

          console.log("DDDDD", data);
          const res = await this.$axios.post(api, data);
          if (res.data.data?.id) {
            let idd = res.data.data.id;
            await this.$axios.post(api, {
              id: idd,
              status: 2,
              createdby_id: 0,
            });
          }
          return;
        }
      });

      return;
      let objects = [];
      if (!id)
        this.data.forEach((el) => {
          if (!objects.includes(el.object_id)) objects.push(el.object_id);
        });
      else objects.push(id);
      let d;
      for (const obj_id of objects) {
        id = obj_id;
        d = this.data.filter((el) => el.object_id == id);
        const r = await this.$axios.get("/accounting/doc/order_good", {
          params: {
            filters: {
              code_doc: -1,
              object_code: id,
              operation_type: 1,
              type: 1,
            },
          },
        });
        console.log("rrrr", r);
        let order_id = r.data.data[0]?.id || null;
        let data_table = [];
        let i = 0;
        d.forEach((el) => {
          // if (i == 0)
          data_table.push({
            good_id: el.good_id,
            name: el.name,
            category_id: el.category,
            amount: el.amount,
            measure: el.measure,
            price_plan: el.price_plan,
            price_real: el.price_real,
          });
          i++;
        });
        let data = {
          createdby_id: 0,
          id: order_id,
          code_doc: -1,
          date_doc: new Date("06.01.22 12:00").toISOString(),
          type: 1,
          status: 1,
          operation_type: 1,
          object_code: d[0].object_id,
          data_table,
        };
        console.log("post data", data);
        let api = "/accounting/doc/order_good";
        let response = await this.$axios.post(api, data);
      }
      return;
      let status = response.data.status == "ok";
      task_id = response.data.data.id;
      if (status) {
        data = { id: el.id, task_id, status: 0 };
        response = await this.$axios.post("/mechti/construction-goods", data);
      }
    },
    async goodsFinanceImport(id) {
      let objects = [];
      if (!id)
        this.data.forEach((el) => {
          if (!objects.includes(el.object_id) && el.object_id != 41) objects.push(el.object_id);
        });
      else objects.push(id);
      let d;
      for (const obj_id of objects) {
        id = obj_id;
        d = this.data.filter((el) => el.object_id == id);
        const r = await this.$axios.get("/accounting/doc/order_good", {
          params: {
            filters: {
              code_doc: -2,
              object_code: id,
              operation_type: 2,
              type: 1,
            },
          },
        });
        let order_id = r.data.data[0]?.id || null;
        let data_table = [];
        let i = 0;
        d.forEach((el) => {
          // if (i == 0)
          data_table.push({
            name: el.name,
            category_id: el.category,
            amount: el.amount,
            measure: null,
            price_plan: el.price,
            price_real: el.price,
          });
          i++;
        });
        let data = {
          createdby_id: 0,
          vendor_id: 4,
          id: order_id,
          code_doc: -2,
          date_doc: new Date("06.01.22 12:00").toISOString(),
          type: 1,
          status: 1,
          operation_type: 2,
          object_code: d[0].object_id,
          data_table,
        };
        console.log("post data", data);
        let api = "/accounting/doc/order_good";
        let response = await this.$axios.post(api, data);
        let idd;
        if (response.data.data?.id) {
          idd = response.data.data.id;
          response = await this.$axios.post(api, {
            id: idd,
            status: 2,
            createdby_id: 0,
          });
          console.log("response", response, response.data.status, response.data.status !== "ok");
          if (response.data.status !== "ok") return;
          let api2 = "/accounting/doc/order_withdrawal";
          d.forEach(async (el) => {
            let order = JSON.parse(JSON.stringify(response.data.data));
            console.log("order", order);
            if (order?.id) {
              let data = {
                date_doc: el.date_doc,
                vendor_id: order.vendor_id,
                object_id: order.object_code,
                value: el.price,
                files: el.files,
                cashbox_id: 1,
                code_doc: -2,
                status: 1,
                operation_type: 3,
                parent_id: order.id,
                parent_name: "AccountingDocOrderGoodModel",
                data: {
                  type: 1,
                  object_code: order.object_code,
                  note: `Импорт финансы id:${el.finance_id}`,
                  finance_id: el.finance_id,
                },
              };

              console.log("DDDDD", data);
              response = await this.$axios.post(api2, data);
              if (response.data.data?.id) {
                response.data.data.id;
                await this.$axios.post(api2, {
                  id: response.data.data.id,
                  status: 2,
                  createdby_id: 0,
                });
              }
            }
          });
        }
      }

      return;
      let status = response.data.status == "ok";
      task_id = response.data.data.id;
      if (status) {
        data = { id: el.id, task_id, status: 0 };
        response = await this.$axios.post("/mechti/construction-goods", data);
      }
    },
    async serviceFinanceImport(id) {
      id = 41;
      let objects = [];
      if (!id)
        this.data.forEach((el) => {
          if (!objects.includes(el.object_id) && el.object_id == 41) objects.push(el.object_id);
        });
      else objects.push(id);
      let d;
      for (const obj_id of objects) {
        id = obj_id;
        d = this.data.filter((el) => el.object_id == id);
        const r = await this.$axios.get("/accounting/doc/order_service", {
          params: {
            filters: {
              code_doc: -2,
              type: id == -1 ? 2 : 1,
              object_code: id == -1 ? 1 : id,
              operation_type: id == -1 ? 12 : 11,
            },
          },
        });
        let order_id = r.data.data[0]?.id || null;
        let data_table = [];
        let i = 0;
        d.forEach((el) => {
          data_table.push({
            name: el.name,
            category_id: el.category,
            service_id: el.item,
            price: el.price,
            description: `импорт. ${el.name} дата ${el.date_doc}`,
          });
        });
        let data = {
          createdby_id: 0,
          vendor_id: 4,
          id: order_id,
          code_doc: -2,
          date_doc: new Date("06.01.22 12:00").toISOString(),
          type: id == -1 ? 2 : 1,
          status: 1,
          operation_type: id == -1 ? 12 : 11,
          object_code: id == -1 ? 1 : id,
          data_table,
        };
        console.log("post data", data);
        let api = "/accounting/doc/order_service";
        let response = await this.$axios.post(api, data);
        let idd;
        if (response.data.data?.id) {
          idd = response.data.data.id;
          response = await this.$axios.post(api, {
            id: idd,
            status: 2,
            createdby_id: 0,
          });

          if (response.data.status !== "ok") return;
          let api2 = "/accounting/doc/order_withdrawal";
          if (1 == 1)
            d.forEach(async (el) => {
              let order = JSON.parse(JSON.stringify(response.data.data));
              console.log("order", order);
              if (order?.id) {
                let data = {
                  date_doc: el.date_doc,
                  vendor_id: order.vendor_id,
                  object_id: id == -1 ? null : order.object_code,
                  value: el.price,
                  files: el.files,
                  cashbox_id: 1,
                  code_doc: -3,
                  status: 1,
                  operation_type: 4,
                  parent_id: order.id,
                  parent_name: "AccountingDocOrderServiceModel",
                  data: {
                    type: id == -1 ? 2 : 1,
                    object_code: order.object_code,
                    note: `Импорт финансы id:${el.finance_id}`,
                    finance_id: el.finance_id,
                  },
                };

                console.log("DDDDD", data);
                response = await this.$axios.post(api2, data);
                if (response.data.data?.id) {
                  response.data.data.id;
                  await this.$axios.post(api2, {
                    id: response.data.data.id,
                    status: 2,
                    createdby_id: 0,
                  });
                }
              }
            });
        }
      }

      return;
      let status = response.data.status == "ok";
      task_id = response.data.data.id;
      if (status) {
        data = { id: el.id, task_id, status: 0 };
        response = await this.$axios.post("/mechti/construction-goods", data);
      }
    },
    async cashOutAnalize() {
      let resp;
      resp = await this.$axios.get("/mechti/finance");
      let dataFull = resp.data.data;
      let d = dataFull.filter((el) => {
        if (el.type == 1 && el.params?.parent !== "construction-goods" && el.data?.typeOperation !== "goods" && el.data?.typeOperation !== "services" && el.status == 2)
          return true;
        return false;
      });
      let category = this.$root.dirs?.["category"];
      let services = this.$root.dirs?.["service"];
      let rows = [];
      d.forEach((el) => {
        let cat;
        let type;
        let item;
        let note;
        let good;
        if (el.data?.typeOperation == "services" || el.data?.typeOperation == "other" || el.data?.typeOperation == "administrative") {
          type = "service";
          if (el.data?.typeSubconto == "Оформление объекта") {
            let s = services.find((c) => c.text == el.data?.typeSubconto?.trim());
            item = s.value;
            cat = s.category_id;
            note = el.name;
          } else if (el.data?.typeSubconto == "Работы черновые") {
            let s = services.find((c) => c.text == el.name);
            if (el.name == "Работы черновые" || !s) {
              s = services.find((c) => c.text == "прочие черновые работы");
            }

            item = s?.value;
            cat = s?.category_id;
            note = el.name;
          } else if (el.data?.typeSubconto == "Работы чистовые") {
            let s = services.find((c) => c.text == "прочие чистовые работы");
            item = s?.value;
            cat = s?.category_id;
            note = el.name;
          } else if (el.data?.typeSubconto == "Прорабы") {
            let s = services.find((c) => c.text == "Покупка объекта");
            item = null;
            cat = s?.category_id;
            note = el.name;
          } else if (el.name == "CRM") {
            let s = services.find((c) => c.text == "Хоз нужды");
            item = s?.value;
            cat = s?.category_id;
            note = el.name;
          } else if (el.name.includes("Покупка объ")) {
            let s = services.find((c) => c.text == "Покупка объекта");
            item = s?.value;
            cat = s?.category_id;
            note = el.name;
          } else {
            let n = el.data?.typeSubconto?.trim();
            if (n == "Реклама") n = "Прочая реклама";
            let s = services.find((c) => c.text == n);
            item = s?.value;
            cat = s?.category_id;
            note = el.name;
          }
        } else if (el.data?.typeOperation == "goods") {
          type = "goods";
          item = el.name;
          note = el.name;
          cat = category.find((c) => c.text == el.data?.typeSubconto?.trim()).value;
        } else {
        }
        rows.push({
          finance_id: el.id,
          date_doc: el.date_doc,
          object_id: el.object_id || -1,
          createdby_id: el.createdby_id,
          name: el.name,
          typeOperation: el.data?.typeOperation,
          category: String(cat || "!!!" + el.data?.typeSubconto),
          type,
          item,
          note,
          price: el.value,
          files: el.files,
        });
      });
      this.makeModel(rows);
      console.log("data", rows);
      this.data = rows;
    },

    async investementImport(id) {
      let d = this.data;
      let api2 = "/accounting/doc/order_receipt";
      d.forEach(async (el) => {
        let data = {
          date_doc: el.date_doc,
          investor_id: el.item,
          object_id: el.object_id,
          value: el.price,
          files: el.files,
          cashbox_id: 1,
          comments: el.note,
          code_doc: -1,
          status: 1,
          operation_type: 9,
          data: {
            note: `Импорт финансы id:${el.finance_id} ${el.name}`,
          },
        };
        let response;
        console.log("DDDDD", data);
        response = await this.$axios.post(api2, data);
        if (response.data.data?.id) {
          response.data.data.id;
          await this.$axios.post(api2, {
            id: response.data.data.id,
            status: 2,
            createdby_id: 0,
          });
        }
      });

      return;
      let status = response.data.status == "ok";
      task_id = response.data.data.id;
      if (status) {
        data = { id: el.id, task_id, status: 0 };
        response = await this.$axios.post("/mechti/construction-goods", data);
      }
    },
    async cashInAnalize() {
      let resp;
      resp = await this.$axios.get("/mechti/finance");
      let dataFull = resp.data.data;
      let d = dataFull.filter((el) => {
        if (el.type == 2 && el.status == 2 && el.data?.typeOperation !== "investement") return true;
        return false;
      });
      let category = this.$root.dirs?.["category"];
      let services = this.$root.dirs?.["service"];
      let rows = [];
      d.forEach((el) => {
        let cat;
        let type;
        let item;
        let note;

        if (el.data?.typeOperation == "investement") {
          let investor = this.$root.dirs["investor"].find((i) => i.value == el.data?.typeSubconto);

          type = "cashIn";
          if (el.data?.typeSubconto == "Оформление объекта") {
            let s = services.find((c) => c.text == el.data?.typeSubconto?.trim());
            item = s.value;
            cat = s.category_id;
            note = el.name;
          } else if (el.data?.typeSubconto == "Работы черновые") {
            let s = services.find((c) => c.text == el.name);
            if (el.name == "Работы черновые" || !s) {
              s = services.find((c) => c.text == "прочие черновые работы");
            }

            item = s?.value;
            cat = s?.category_id;
            note = el.name;
          } else if (el.data?.typeSubconto == "Работы чистовые") {
            let s = services.find((c) => c.text == "прочие чистовые работы");
            item = s?.value;
            cat = s?.category_id;
            note = el.name;
          } else if (el.data?.typeSubconto == "Прорабы") {
            let s = services.find((c) => c.text == "Покупка объекта");
            item = null;
            cat = s?.category_id;
            note = el.name;
          } else if (el.name == "CRM") {
            let s = services.find((c) => c.text == "Хоз нужды");
            item = s?.value;
            cat = s?.category_id;
            note = el.name;
          } else if (el.name.includes("Покупка объ")) {
            let s = services.find((c) => c.text == "Покупка объекта");
            item = s?.value;
            cat = s?.category_id;
            note = el.name;
          } else {
            let n = "";
            let s = services.find((c) => c.text == n);
            item = investor.value;
            cat = s?.category_id;
            note = el.description;
          }
        } else if (el.data?.typeOperation == "goods") {
          type = "goods";
          item = el.name;
          note = el.description;
          cat = category.find((c) => c.text == el.data?.typeSubconto?.trim()).value;
        } else {
        }
        rows.push({
          finance_id: el.id,
          date_doc: el.date_doc,
          object_id: el.object_id || -1,
          createdby_id: el.createdby_id,
          name: el.name,
          typeOperation: el.data?.typeOperation,
          category: String(cat || "!!!" + el.data?.typeSubconto),
          type,
          item,
          note,
          price: el.value,
          files: el.files,
        });
      });
      this.makeModel(rows);
      console.log("data", rows);
      this.data = rows;
    },
    async constructionGoodsAnalize() {
      let resp;
      resp = await this.$axios.get("/mechti/finance");
      let dataFull = resp.data.data;
      let d = dataFull.filter((el) => {
        if (el.params?.parent == "construction-goods" && el.status == 2) return true;
        return false;
      });
      resp = await this.$axios.get("/mechti/construction-goods");
      let goods = resp.data.data;
      let category = this.$root.dirs?.["category"].filter((el) => el.type == 2);
      console.log("cat", category);
      let rows = [];
      d.forEach((el) => {
        let good = goods.find((g) => g.id == el.params.parent_id);
        let cat = category.find((c) => c.text == el.data?.typeSubconto?.trim());
        rows.push({
          finance_id: el.id,
          date_doc: el.date_doc,
          object_id: good?.object_id,
          createdby_id: good?.createdby_id,
          good_id: el.params.parent_id,
          name: good?.name,
          typeOperation: el.data?.typeOperation,
          category: cat?.value || "!!!" + el.data?.typeSubconto,
          amount: good?.amount,
          measure: good?.measure,
          price_plan: good?.price ? good?.price : el.value,
          price_real: el.value,
          files: el.files,
        });
      });
      this.makeModel(rows);
      console.log("data", rows);
      this.data = rows;
    },
    makeModel(rows) {
      const head = function (key) {
        return { name: key, title: key, type: "string", sortable: true };
      };
      let keys = [];
      for (const key of Object.keys(rows[0])) {
        if (!keys.includes(key) && key != "files") keys.push(key);
      }
      let model = keys.map(head);
      this.model = model;
    },

    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },

    async getBalance(filter = {}) {
      const response = await this.$axios.get("/mechti/finance/balance", {
        params: { filters: filter },
      });
      this.balance = response.data.data;
    },
  },
};
</script>
